import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import React, { ReactNode } from 'react';
import { FreeMode } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Book } from '~/api/book/bookApiTypes';
import { Slider, SliderProps } from '~/atomic/atom/Slider';
import { BookCard } from '~/feature/book/BookCard/BookCard';
import { BookCardSkeleton } from '~/feature/book/BookCard/BookCardSkeleton';
import { DefaultBookCardProps } from '~/feature/book/BookCard/DefaultBookCard';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { useAppSelector } from '~/store';

export interface BooksSliderProps extends SliderProps {
  books: Book[];
  bookCardProps?: Omit<DefaultBookCardProps, 'book'>;
  bookCard?: ReactNode;
}

export const BooksSlider = ({
  books,
  bookCardProps = {},
  isLoading,
  bookCard,
  ...props
}: BooksSliderProps) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  return (
    <Slider
      modules={[FreeMode]}
      freeMode={isMobileSize}
      isLoading={isLoading}
      {...props}
    >
      {isLoading
        ? Array(4).fill('dummy').map((dummy, index) => (
          <SwiperSlide key={`book-skeleton-${index}`}>
            <BookCardSkeleton />
          </SwiperSlide>
        ))
        : books?.map((book, index) => (
          <SwiperSlide key={book.id} virtualIndex={index}>
            {bookCard ?? (
              <BookCard
                key={book.id}
                book={book}
                {...bookCardProps}
              />
            )}
          </SwiperSlide>
        ))}
    </Slider>
  );
};
