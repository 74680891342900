import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { feedLatestBooksSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { FeedBooksSlider } from '~/atomic/page/index/FeedBooksSlider';
import { PageTitleStyle, TextTagStyle } from '~/atomic/Typography';
import { buyBookFromRecentlyUpdateQueryName } from '~/feature/yandex/trackingBuyRecentlyUpdate';
import { useAppSelector } from '~/store';

export const FeedRecentlyUpdated = () => {
  const books = useAppSelector(feedLatestBooksSelector);
  const { isGedFeedLoading } = useAppSelector(feedSelector);

  return (
    <SCFeedRecentlyUpdated>
      <Link
        as="/genre?sortingType=last-update"
        href="/genre?sortingType=last-update"
        passHref
        prefetch={false}
      >
        <SCTitle>
          Недавно обновлены
          <SCTitleArrowIcon />
        </SCTitle>
      </Link>
      <SCSubTitle>
        Только что из-под авторского пера
      </SCSubTitle>
      <FeedBooksSlider
        books={books}
        isLoading={isGedFeedLoading}
        bookCardProps={{ bookLinkQueries: { [buyBookFromRecentlyUpdateQueryName]: true } }}
      />
    </SCFeedRecentlyUpdated>
  );
};

const SCFeedRecentlyUpdated = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;
  }
`;

const SCTitleArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 8px;
  color: var(--black-color);
  transition: color 0.3s ease;
`;

const SCTitle = styled.a`
  ${PageTitleStyle};
  display: flex;
  align-items: baseline;

  &:hover {
    color: var(--primary-hover-color);

    ${SCTitleArrowIcon} {
      color: var(--primary-hover-color);
    }
  }
`;

const SCSubTitle = styled.span`
  ${TextTagStyle};
  display: flex;
  line-height: 20px;
`;
