import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { changeNewFeedTab } from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { TextSmallStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

enum FeedFilterLables {
  Popularity = 'Что почитать?',
  Recommended = 'Вам рекомендуют',
  Bestseller = 'Бестселлеры',
  Exclusive = 'Эксклюзивно',
  News = 'Новинки',
  Discount = 'Скидки',
  Listen = 'Слушать',
  Abonnement = 'По абонементу',
}

const TABS_HEIGHT_WITH_OFFSET_TOP = 150;

export const FeedTabs = () => {
  const dispatch = useAppDispatch();
  const { feedTab } = useAppSelector(feedSelector);
  const feedTabRef = useRef<HTMLDivElement>(null);
  const tabsInitialHeight = useRef<number>(0);

  useEffect(() => {
    if (feedTabRef.current) {
      tabsInitialHeight.current = feedTabRef.current.offsetTop;
    }
  }, []);

  const changeActiveFeedTabHandler = async (newFeedTab: FeedTabEnum) => {
    const { offsetTop } = feedTabRef.current;
    const tabsInView = window.scrollY > (offsetTop - TABS_HEIGHT_WITH_OFFSET_TOP);
    await dispatch(changeNewFeedTab(newFeedTab));

    if (window?.scrollTo && feedTabRef.current && tabsInView) {
      window.scrollTo({ top: tabsInitialHeight.current });
    }
  };

  return (
    <SCFeedTabs
      ref={feedTabRef}
    >
      <SCTabs>
        {Object.entries(FeedTabEnum).map(([key, value]) => (
          <SCTab
            key={value}
            onClick={() => changeActiveFeedTabHandler(value)}
            data-is-active={feedTab === value}
          >
            <SCTabIconWrapper>
              <img src={`/icon/components/Feed/${value}Icon.svg`} alt="" />
            </SCTabIconWrapper>
            <SCTabText>{FeedFilterLables[key]}</SCTabText>
          </SCTab>
        ))}
      </SCTabs>
    </SCFeedTabs>
  );
};

const SCFeedTabs = styled.div`
  overflow: hidden;
  height: 70px;
  background-color: var(--bg-color);
  position: relative;

  ${lessThan('sm')} {
    position: sticky;
    top: 0;
    box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.3);
    z-index: 14;
    height: 78px;
    margin-left: -12px;
    margin-right: -12px;
    padding-top: 8px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: -4px;
    z-index: 2;
    overflow: hidden;
    background-color: #E0E0E0;
  }
`;

const SCTabs = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 7px;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 12px;
  
  
  > *:not(:last-child) {
    margin-right: 24px;
  }
  
  &::-webkit-scrollbar {
    height: 0 !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const SCTabText = styled.span`
  ${TextSmallStyle};
  color: var(--black-color);
`;

const SCTabIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
`;

const SCTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3px;
  cursor: pointer;
  transition: all 0.3s;
  flex-shrink: 0;
  width: max-content;
  position: relative;
  
  &:after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -6px;
    background-color: #E0E0E0;
    z-index: 11;
  }
  
  &[data-is-active="true"] {
    cursor: default;

    &:after {
      background-color: var(--black-color);
      bottom: -5px;
    }
    
    ${SCTabText} {
      font-weight: bold;
    }
    
    ${SCTabIconWrapper} {
      background-color: var(--blue-color);
    }
  }
`;
