import React from 'react';
import styled from 'styled-components';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { changeNewFeedTab } from '~/atomic/page/index/feed.data';
import { FeedBooksSlider } from '~/atomic/page/index/FeedBooksSlider';
import { FeedDiscountBookISkeletonItem } from '~/atomic/page/index/FeedDiscountBooks/FeedDiscountBookISkeletontem';
import { FeedDiscountBookItem } from '~/atomic/page/index/FeedDiscountBooks/FeedDiscountBookItem';
import { useGetFeedDiscountBooks } from '~/atomic/page/index/FeedDiscountBooks/feedDiscountBooks.data';
import { PageTitleStyle, TextBoldStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const FeedDiscountBooks = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const { data, isFetching } = useGetFeedDiscountBooks();

  const openDiscountTabHandler = () => {
    dispatch(changeNewFeedTab(FeedTabEnum.Discount));
  };

  if (data?.length === 0 && !isFetching) {
    return null;
  }

  return (
    <SCFeedDiscountBooks>
      <SCTitle
        onClick={openDiscountTabHandler}
      >
        Скидки сегодня
        <SCArrowIcon />
      </SCTitle>
      {isMobileSize
        ? (
          <FeedBooksSlider
            books={data}
            isLoading={isFetching}
          />
        )
        : (
          <SCList>
            {isFetching
              ? Array(3)
                .fill('dummy')
                .map((dummy, index) => (
                  <FeedDiscountBookISkeletonItem key={index} />
                ))
              : data?.map((book) => (
                <FeedDiscountBookItem key={book?.slug} book={book} />
              ))}
          </SCList>
        )}
    </SCFeedDiscountBooks>
  );
};

const SCFeedDiscountBooks = styled.div`
  ${PaperStyle};
  padding: 16px 24px;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;

  ${lessThan('md')} {
    padding: 12px;
  }

  ${lessThan('sm')} {
    position: relative;
    background-color: transparent;
    max-width: none;
    padding: 0;
    box-shadow: none;
    
    .swiper-slide {
      height: auto;
    }
  }
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 8px;
  color: var(--gray-color);
  transition: color 0.3s ease;
`;

const SCTitle = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    color: var(--primary-hover-color);
    
    ${SCArrowIcon} {
      color: var(--primary-hover-color);
    }
  }

  ${lessThan('md')} {
    margin-bottom: 12px;
  }

  ${lessThan('sm')} {
    margin-bottom: 0;
    ${PageTitleStyle};
    display: flex;
    align-items: center;
  }
`;

const SCList = styled.ul`
  display: flex;
  flex-direction: column;
  
  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  ${lessThan('md')} {
    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
