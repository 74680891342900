import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { RootState } from '~/store';

export const buyBookFromRecentlyUpdateQueryName = 'buyBookFromRecentlyUpdate';

export const trackingGoToBookFromRecentlyUpdate = createAsyncThunk(
  'trackingGoToBookFromRecentlyUpdate',
  () => {
    ym('reachGoal', 'go-to-recently-update');
  },
);

export const saveMoveOnTrackingRecentlyUpdateBook = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'saveMoveOnTrackingRecentlyUpdateBook',
  async ({ book }, thunkAPI) => {
    const parsedLocation = qs.parseUrl(String(window.location));

    if (parsedLocation.query[buyBookFromRecentlyUpdateQueryName]) {
      parsedLocation.query[buyBookFromRecentlyUpdateQueryName] = undefined;

      if (window.localStorage) {
        const oldStore = JSON.parse(localStorage.getItem(buyBookFromRecentlyUpdateQueryName)) ?? {};

        const newStore = {
          ...oldStore,
          [book.slug]: true,
        };

        localStorage.setItem(buyBookFromRecentlyUpdateQueryName, JSON.stringify(newStore));
      }

      const url = new URL(qs.stringifyUrl(parsedLocation));

      const redirectUrl = url.pathname + url.search + url.hash;
      await Router.replace(Router.pathname, redirectUrl, { shallow: true });
    }
  },
);

export const trackingBuyBookFromRecentlyUpdate = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'trackingBuyBookFromRecentlyUpdate',
  async ({ book }, thunkAPI) => {
    if (window.localStorage) {
      const store = JSON.parse(localStorage.getItem(buyBookFromRecentlyUpdateQueryName)) ?? {};

      const isTrackingBook = book.slug in store;

      if (isTrackingBook) {
        ym('reachGoal', 'buy-book-from-recently-update');
        delete store[book.slug];
        localStorage.setItem(buyBookFromRecentlyUpdateQueryName, JSON.stringify(store));
      }
    }
  },
);
