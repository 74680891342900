import React, { useEffect, useState } from 'react';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { getFeed } from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { MainFeedSliders } from '~/atomic/page/index/MainFeedSliders';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { useAppDispatch, useAppSelector } from '~/store';

export const NewFeedPopularity = () => {
  const dispatch = useAppDispatch();
  const { feedTab } = useAppSelector(feedSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isPopularityTab = feedTab === FeedTabEnum.Popularity;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isPopularityTab && !isFirstLoad) {
      dispatch(getFeed());
    }
    setIsFirstLoad(false);
  }, [isLoggedIn, isPopularityTab]);

  if (!isPopularityTab) return null;

  return (
    <MainFeedSliders />
  );
};
