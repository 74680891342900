import { Book } from '~/api/book/bookApiTypes';
import { GetWidgetDiscountBooks } from '~/api/book/discountBooksApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const discountBooksApi = {
  [namespace]: '/v1/books',

  async getWidgetDiscountBooks(data: GetWidgetDiscountBooks) {
    try {
      const response = await provider.get<
      ApiResponse<Book[], RejectedRequest<void>>
      >(`${this[namespace]}/widget-discount-books`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
