import React from 'react';
import styled from 'styled-components';

import { FeedBestsellersBooks } from '~/atomic/page/index/FeedBestsellersBooks';
import { FeedBookriverRecommends } from '~/atomic/page/index/FeedBookriverRecommends';
import { FeedDiscountBooks } from '~/atomic/page/index/FeedDiscountBooks/FeedDiscountBooks';
import { FeedGenreList } from '~/atomic/page/index/FeedGenreList';
import { FeedHotAndExclusiveBooks } from '~/atomic/page/index/FeedHotAndExclusiveBooks';
import { FeedMobileLink } from '~/atomic/page/index/FeedMobileLink';
import { FeedPopularThemesBanner } from '~/atomic/page/index/FeedPopularThemesBanner/FeedPopularThemesBanner';
import { FeedRecentlyUpdated } from '~/atomic/page/index/FeedRecentlyUpdated';
import { FeedSidebarBanner } from '~/atomic/page/index/FeedSidebarBanner';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export const MainFeedSliders = () => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  return (
    <SCMainFeedSliders>
      <FeedHotAndExclusiveBooks />
      <FeedPopularThemesBanner />
      <FeedBookriverRecommends />
      <SCFeedMainMobileLinkWrapper>
        {isMobileSize ? (
          <>
            <FeedMobileLink />
          </>
        ) : null}
      </SCFeedMainMobileLinkWrapper>
      <FeedBestsellersBooks />
      <SCFeedSidebarBannerWrapper>
        {isMobileSize ? (
          <FeedSidebarBanner />
        ) : null}
      </SCFeedSidebarBannerWrapper>
      <SCFeedMainDiscountBooksWrapper>
        {isMobileSize ? (
          <FeedDiscountBooks />
        ) : null}
      </SCFeedMainDiscountBooksWrapper>
      <FeedRecentlyUpdated />
      <FeedGenreList />
    </SCMainFeedSliders>
  );
};

const SCMainFeedSliders = styled.div`
  > *:not(:last-child) {
    margin-bottom: 30px;

    ${lessThan('sm')} {
      margin-bottom: 20px;
    }
  }
`;

const SCFeedMainDiscountBooksWrapper = styled.div`
  display: none;

  ${lessThan('sm')} {
    display: block;
  }
`;

const SCFeedSidebarBannerWrapper = styled.div`
  display: none;

  ${lessThan('sm')} {
    display: block;
  }
`;

const SCFeedMainMobileLinkWrapper = styled.div`
  display: none;

  ${lessThan('sm')} {
    display: block;
  }
`;
