import React, { FC } from 'react';

import { BookSortingEnum } from '~/api/book/bookApiTypes';
import { SortTemplate } from '~/atomic/molecula/SortTemplate';

const sortingOptions = {
  [BookSortingEnum.RecommendationScore]: 'Высокая оценка',
  [BookSortingEnum.RecommendationNumberOfRecommenders]: 'Количество рекомендаций',
  [BookSortingEnum.BestsellerLastMonth]: 'Бестселлеры',
  [BookSortingEnum.Newest]: 'Новинки',
} as {
  [key in BookSortingEnum]: string;
};

interface NewFeedRecommendationSortProps {
  bookSorting: BookSortingEnum;
  onChangeBookSort(value: BookSortingEnum): void;
  className?: string;
}

export const NewFeedRecommendationSort: FC<NewFeedRecommendationSortProps> = ({
  bookSorting, onChangeBookSort, className,
}) => {
  return (
    <SortTemplate
      sortingOptions={sortingOptions}
      onChangeBookSort={onChangeBookSort}
      bookSorting={bookSorting}
      className={className}
    />
  );
};
