import React from 'react';
import styled from 'styled-components';

import { GenreLink } from '~/atomic/atom/links/dynamicLinks';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { feedActiveGenresTagsSelector } from '~/atomic/page/index/feed.selector';
import { PageTitleStyle, TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export const FeedGenreList = () => {
  const nestedGenres = useAppSelector(feedActiveGenresTagsSelector);

  if (nestedGenres.length === 0) return <></>;

  return (
    <SCFeedGenreList>
      <SCTitle>
        Популярные темы
      </SCTitle>
      <SCListWrapper>
        {nestedGenres.map((nestedGenre) => (
          <SCList key={nestedGenre.slug}>
            <GenreLink key={nestedGenre.slug} slug={nestedGenre.slug}>
              <SCListTitle>
                {nestedGenre.name}
              </SCListTitle>
            </GenreLink>
            {nestedGenre.tags.map((childGenre) => (
              <GenreLink
                key={`${nestedGenre.slug}/${childGenre.slug}`}
                slug={`${nestedGenre.slug}/${childGenre.slug}`}
              >
                <SCItem>
                  {childGenre.name}
                </SCItem>
              </GenreLink>
            ))}
          </SCList>
        ))}
      </SCListWrapper>
    </SCFeedGenreList>
  );
};

const SCFeedGenreList = styled.div`
  ${PaperStyle};
  padding: 24px 20px;
  
  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  ${lessThan('sm')} {
    padding: 16px;
  }
`;

const SCTitle = styled.span`
  ${PageTitleStyle};
  display: flex;
`;

const SCListWrapper = styled.div`
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 70px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  
  ${lessThan('md')} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  } 
  
  ${lessThan('sm')} {
    grid-template-columns: 1fr 1fr;
  }
  
  ${lessThan('xxs')} {
    grid-template-columns: 1fr;
  }
`;

const SCList = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${lessThan('xxs')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const SCListTitle = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: var(--black-color);
  margin-bottom: 10px;

  ${lessThan('xxs')} {
    width: 100%;
  }
`;

const SCItem = styled.a`
  ${TextRegStyle};
  color: var(--black-color);
  padding-left: 10px;

  ${lessThan('md')} {
    padding-left: 0;
  }


  ${lessThan('xxs')} {
    display: inline-flex;
    
    &:not(:last-child) {
      &:after {
        content: ', ';
        padding-right: 4px;
      }
    }
  }
`;
