export const feedPopularThemesBannerItems = [
  {
    themeSlug: 'yumor',
    includes: ['all'],
    alt: 'Юмор',
  },
  {
    themeSlug: 'silnaya-geroinya',
    includes: ['all'],
    alt: 'Сильная героиня',
  },
  {
    themeSlug: 'raznitsa-v-vozraste',
    includes: ['lyubovnyi-roman'],
    alt: 'Разница в возрасте',
  },
  {
    themeSlug: 'priklyucheniya',
    includes: ['all'],
    excludes: ['erotika'],
    alt: 'Приключения',
  },
  {
    themeSlug: 'popadanka',
    includes: ['popadantsy'],
    alt: 'Попаданка',
  },
  {
    themeSlug: 'pervaya-lyubov',
    includes: ['lyubovnyi-roman', 'molodezhnaya-proza'],
    alt: 'Первая любовь',
  },
  {
    themeSlug: 'ochen-otkrovenno',
    includes: ['lyubovnyi-roman', 'erotika'],
    alt: 'Очень откровенно',
  },
  {
    themeSlug: 'ot-nenavisti-do-lyubvi',
    includes: ['lyubovnyi-roman', 'molodezhnaya-proza'],
    alt: 'От ненависти до любви',
  },
  {
    themeSlug: 'oborotni',
    includes: ['fantastika', 'fentezi'],
    alt: 'Оборотни',
  },
  {
    themeSlug: 'mistika',
    includes: ['mistika'],
    alt: 'Мистика',
  },
  {
    themeSlug: 'lyubov-i-strast',
    includes: ['lyubovnyi-roman', 'erotika'],
    alt: 'Любовь и страсть',
  },
  {
    themeSlug: 'lyubovnyi-treugolnik',
    includes: ['lyubovnyi-roman', 'molodezhnaya-proza'],
    alt: 'Любовный треугольник',
  },
  {
    themeSlug: 'kriminal',
    includes: ['all'],
    excludes: ['fantastika', 'fentezi'],
    alt: 'Криминал',
  },
  {
    themeSlug: 'intrigi',
    includes: ['all'],
    excludes: ['erotika'],
    alt: 'Интриги',
  },
  {
    themeSlug: 'drakony',
    includes: ['lyubovnyi-roman', 'fentezi'],
    alt: 'Драконы',
  },
  {
    themeSlug: 'demony',
    includes: ['lyubovnyi-roman', 'fentezi'],
    alt: 'Демоны',
  },
  {
    themeSlug: 'vlastnyi-geroi',
    includes: ['lyubovnyi-roman'],
    alt: 'Властный герой',
  },
  {
    themeSlug: 'vedma',
    includes: ['lyubovnyi-roman', 'fentezi'],
    alt: 'Ведьма',
  },
];
