import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import {
  buyBookFromFeedBannerQueryName,
  trackingGoToBookFromFeedBanner,
} from '~/feature/yandex/trackingBuyBookFromFeedBanner';
import { useIsClientRender } from '~/lib/hooks/useIsClientRender';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const FeedSidebarBanner = () => {
  const dispatch = useAppDispatch();
  const { randomSidebarBanners } = useAppSelector(feedSelector);
  const [randomBanner, secondRandomBanner] = randomSidebarBanners;
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isClient = useIsClientRender();

  const goToFeedBookBanner = () => {
    dispatch(trackingGoToBookFromFeedBanner());
  };

  if (!isClient || !randomBanner) return null;

  if (isMobileSize) {
    return (
      <SCFeedSidebarBanner>
        <Link passHref href={`${randomBanner?.url}?${buyBookFromFeedBannerQueryName}=true`}>
          <SCLink onClick={goToFeedBookBanner}>
            <SCBanner
              src={randomBanner?.image}
              width="243px"
              height="344px"
              alt={randomBanner?.alt}
              title={randomBanner?.alt}
            />
          </SCLink>
        </Link>
        <Link passHref href={`${secondRandomBanner?.url}?${buyBookFromFeedBannerQueryName}=true`}>
          <SCLink onClick={goToFeedBookBanner}>
            <SCBanner
              src={secondRandomBanner?.image}
              width="243px"
              height="344px"
              alt={secondRandomBanner?.alt}
              title={secondRandomBanner?.alt}
            />
          </SCLink>
        </Link>
      </SCFeedSidebarBanner>
    );
  }

  return (
    <SCFeedSidebarBanner>
      <Link passHref href={`${randomBanner?.url}?${buyBookFromFeedBannerQueryName}=true`}>
        <SCLink onClick={goToFeedBookBanner}>
          <SCBanner
            src={randomBanner?.image}
            width="600px"
            height="850px"
            alt={randomBanner?.alt}
            title={randomBanner?.alt}
          />
        </SCLink>
      </Link>
    </SCFeedSidebarBanner>
  );
};

const SCFeedSidebarBanner = styled.div`
  ${PaperStyle};
  
  ${lessThan('sm')} {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
    
    > *:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const SCBanner = styled(Image)`
  background: hsla(0,0%,74.5%,.2);
`;

const SCLink = styled.a`
`;
