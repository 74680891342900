import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import {
  Autoplay,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { feedSelector } from '~/atomic/page/index/feed.selector';
import { isMobileSizeSelector, windowSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export const FeedBanners = () => {
  const { size } = useAppSelector(windowSizeSelector);
  const { randomMainBanners } = useAppSelector(feedSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const width = size.width > 0 ? size.width : 1920;

  const imageWidth = width >= 1200 ? 1200 : size.width - 24;
  const imageHeight = Math.round(imageWidth * (isMobileSize ? 0.312 : 0.155));

  return (
    <div>
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={4}
        speed={700}
        pagination
        autoplay={{ delay: 6000 }}
      >
        {randomMainBanners
          .map((banner, index) => (
            <SwiperSlide key={index}>
              <Link prefetch={false} href={banner.url}>
                <a>
                  <SCFeedBanner
                    width={imageWidth}
                    height={imageHeight}
                    src={isMobileSize ? banner.imageMob : banner.image}
                    alt={banner.alt}
                    quality={90}
                  />
                </a>
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

const SCFeedBanner = styled(Image)`
  width: 100%;
  margin-bottom: 32px;
  background-color: hsla(0,0%,74.5%,.2);
  height: calc((100vw - 24px) * 0.155);
  
  ${lessThan('sm')} {
    height: calc((100vw - 24px) * 0.312);
  }
`;
