import React from 'react';
import styled from 'styled-components';

import { Skeleton, SkeletonInput } from '~/atomic/atom/Skeleton';

export const FeedDiscountBookISkeletonItem = () => {
  return (
    <SCFeedDiscountBookItem>
      <SCBookCover>
        <SkeletonInput style={{ width: 90, height: 120 }} active />
      </SCBookCover>
      <SCBookInfo>
        <Skeleton
          paragraph={{ rows: 2 }}
        />
      </SCBookInfo>
    </SCFeedDiscountBookItem>
  );
};

const SCFeedDiscountBookItem = styled.li`
  display: flex;
`;

const SCBookCover = styled.div`
  flex-shrink: 0;
`;

const SCBookInfo = styled.div`
  margin-left: 24px;
  width: 100%;
`;
