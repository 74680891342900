import React, { FC } from 'react';
import styled from 'styled-components';

import { BooksSlider, BooksSliderProps } from '~/atomic/molecula/BooksSlider';
import { lessThan } from '~/lib/mediaQuery';

export const FeedBooksSlider: FC<
Omit<BooksSliderProps, 'slidesPerView' | 'slidesPerGroup' | 'breakpoints' | 'spaceBetween'>
> = (props) => {
  return (
    <SCSwiper
      slidesPerView={3}
      slidesPerGroup={3}
      spaceBetween={6}
      breakpoints={{
        1023: {
          slidesPerView: 4, slidesPerGroup: 2, spaceBetween: 24,
        },
        768: {
          slidesPerView: 3, slidesPerGroup: 2, spaceBetween: 12,
        },
        576: {
          slidesPerView: 4, slidesPerGroup: 2, spaceBetween: 6,
        },
      }}
      {...props}
    />
  );
};

const SCSwiper = styled(BooksSlider)` && {
    padding-bottom: 30px;

    ${lessThan('sm')} {
      padding-bottom: 15px;
    }
  }

  .swiper-slide {
    margin-right: 24px;
    max-width: calc((780px - 24px * 3) / 4);
  }

  ${lessThan('lg')} {
    .swiper-slide {
      margin-right: 24px;
      max-width: calc(((100%) / 4));
    }
  }

  ${lessThan('md')} {
    .swiper-slide {
      margin-right: 24px;
      max-width: calc(((100%) / 3));
    }
  }

  ${lessThan('sm')} {
    .swiper-slide {
      margin-right: 12px;
      max-width: calc(((100%) / 4));
    }
  }

  ${lessThan('xs')} {
    .swiper-slide {
      margin-right: 12px;
      max-width: calc(((100%) / 3));
    }
  }
`;
