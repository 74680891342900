import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperProps } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { lessThan } from '~/lib/mediaQuery';
import { WithChildren } from '~/lib/WithChildren';

export interface SliderProps extends SwiperProps {
  className?: string;
  isLoading?: boolean;
  isShowNavigation?: boolean;
}

export const Slider = ({
  isLoading,
  slidesPerGroup,
  slidesPerView,
  breakpoints,
  spaceBetween,
  children,
  modules = [],
  isShowNavigation = true,
  ...props
}: SliderProps & WithChildren) => {
  const [isShowPrevButton, setIsShowPrevButton] = useState(false);
  const [isShowNextButton, setIsShowNextButton] = useState(true);
  const [swiper, initSwiper] = useState((null as any) as SwiperClass);

  const nextEl = useRef<HTMLDivElement>(null);
  const prevEl = useRef<HTMLDivElement>(null);

  const onClickPrevButtonHandler = () => {
    swiper.slidePrev();
  };

  const onClickNextButtonHandler = () => {
    swiper.slideNext();
  };

  return (
    <>
      {isShowNavigation && isShowPrevButton && !isLoading ? (
        <SCSamplePrevArrow
          ref={prevEl}
          onClick={onClickPrevButtonHandler}
        />
      ) : null}
      <SCSwiper
        modules={[Navigation, FreeMode, ...modules]}
        spaceBetween={spaceBetween}
        speed={1000}
        // @ts-ignore
        navigation={{
          nextEl: nextEl.current,
          prevEl: prevEl.current,
        }}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        breakpoints={breakpoints}
        onSlideChange={() => {
          if (isShowNavigation) {
            setIsShowPrevButton(!swiper.isBeginning);
            setIsShowNextButton(!swiper.isEnd);
          }
        }}
        onSwiper={(swiper) => {
          initSwiper(swiper);
        }}
        {...props}
      >
        {children}
      </SCSwiper>
      {isShowNavigation && isShowNextButton && !isLoading ? (
        <SCSampleNextArrow
          ref={nextEl}
          onClick={onClickNextButtonHandler}
        />
      ) : null}
    </>
  );
};

const SCSampleNextArrow = styled.div`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background: var(--white-color) !important;
  box-shadow: 0 5px 8px rgba(53, 63, 72, 0.4);
  cursor: pointer;
  z-index: 9;
  transition: 0.3s;
  color: black !important;

  &:after {
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-image: url("/icon/next-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  ${lessThan('lg')} {
    width: 40px !important;
    height: 40px !important;
    right: -5px;
  }

  ${lessThan('sm')} {
    width: 30px !important;
    height: 30px !important;
    right: -5px;
  }

  &:hover {
    box-shadow: 0 3px 5px rgba(53, 63, 72, 0.2);
  }
`;

const SCSamplePrevArrow = styled.div`
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  box-shadow: 0 5px 8px rgba(53, 63, 72, 0.4);
  background: var(--white-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  z-index: 9;
  transition: 0.3s;
  color: black !important;

  &:after {
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-image: url("/icon/prev-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  ${lessThan('lg')} {
    width: 40px !important;
    height: 40px !important;
    left: -5px;
  }

  ${lessThan('sm')} {
    width: 30px !important;
    height: 30px !important;
    left: -5px;
  }

  &:hover {
    box-shadow: 0 3px 5px rgba(53, 63, 72, 0.2);
  }
`;

const SCSwiper = styled(Swiper)` & {
  padding: 15px 10px 25px;

  .swiper-slide {
    height: auto;
  }
}

  ${lessThan('sm')} {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
`;
