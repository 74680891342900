import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { RootState } from '~/store';

export const buyBookFromBookriverRecommendsQueryName = 'buyBookFromBookriverRecommends';

export const trackingGoToBookFromBookriverRecommends = createAsyncThunk(
  'trackingGoToBookFromBookriverRecommends',
  () => {
    ym('reachGoal', 'go-to-bookriver-recommends');
  },
);

export const saveMoveOnTrackingBookriverRecommendsBook = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'saveMoveOnTrackingBookriverRecommendsBook',
  async ({ book }, thunkAPI) => {
    const parsedLocation = qs.parseUrl(String(window.location));

    if (parsedLocation.query[buyBookFromBookriverRecommendsQueryName]) {
      parsedLocation.query[buyBookFromBookriverRecommendsQueryName] = undefined;

      if (window.localStorage) {
        const oldStore = JSON.parse(localStorage.getItem(buyBookFromBookriverRecommendsQueryName)) ?? {};

        const newStore = {
          ...oldStore,
          [book.slug]: true,
        };

        localStorage.setItem(buyBookFromBookriverRecommendsQueryName, JSON.stringify(newStore));
      }
      const url = new URL(qs.stringifyUrl(parsedLocation));

      const redirectUrl = url.pathname + url.search + url.hash;
      await Router.replace(Router.pathname, redirectUrl, { shallow: true });
    }
  },
);

export const trackingBuyBookFromBookriverRecommends = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'trackingBuyBookFromBookriverRecommends',
  async ({ book }, thunkAPI) => {
    if (window.localStorage) {
      const store = JSON.parse(localStorage.getItem(buyBookFromBookriverRecommendsQueryName)) ?? {};

      const isTrackingBook = book.slug in store;

      if (isTrackingBook) {
        ym('reachGoal', 'buy-book-from-bookriver-recommends');
        delete store[book.slug];
        localStorage.setItem(buyBookFromBookriverRecommendsQueryName, JSON.stringify(store));
      }
    }
  },
);
