import React, { FC } from 'react';
import styled from 'styled-components';

import { Book } from '~/api/book/bookApiTypes';
import { BookLink, GenreLink } from '~/atomic/atom/links/dynamicLinks';
import { SimpleCover } from '~/atomic/atom/SimpleCover';
import { TextBoldStyle, TextSmallStyle } from '~/atomic/Typography';
import { BookCardBadgers } from '~/feature/book/BookCard/BookCardBadgers';
import { BookCardExclusive } from '~/feature/book/BookCard/BookCardExclusive';
import { BookCardReadStatusBadger } from '~/feature/book/BookCard/BookCardReadStatusBadger';
import { lessThan } from '~/lib/mediaQuery';

export const FeedDiscountBookItem: FC<{ book: Book }> = ({ book }) => {
  return (
    <SCFeedDiscountBookItem>
      <SCBookCover>
        <BookCardExclusive isExclusive={book.exclusive} />
        <BookCardBadgers
          bought={book?.myBook?.buy}
          discount={book?.discount}
          book={book}
          isShowPartnerProgram={false}
        />
        <BookCardReadStatusBadger status={book?.myBook?.readStatus} />
        <BookLink slug={book.slug}>
          <a>
            <SimpleCover
              width={100}
              font={{
                min: 4,
                max: 8,
              }}
              coverImages={book?.coverImages}
              book={{
                author: book.authors?.[0]?.name ?? 'Имя автора',
                title: book?.name ?? 'Название книги',
              }}
            />
          </a>
        </BookLink>
      </SCBookCover>
      <SCBookInfo>
        <BookLink
          slug={book?.slug}
        >
          <SCBookName>
            {book.name}
          </SCBookName>
        </BookLink>
        <SCBookGenreWrapper>
          {book.genres.filter((b, index) => index < 2).map((genre) => (
            <SCBookGenre
              key={genre?.slug}
            >
              <GenreLink
                slug={genre.slug}
              >
                <SCGenreLink>
                  {genre.name}
                </SCGenreLink>
              </GenreLink>
            </SCBookGenre>
          ))}
        </SCBookGenreWrapper>
        <SCBookPrice>
          <SCOldPrice>
            {book.price}
            {' '}
            ₽
          </SCOldPrice>
          <SCNewPrice>
            {book?.discount?.price}
            {' '}
            ₽
          </SCNewPrice>
        </SCBookPrice>
      </SCBookInfo>
    </SCFeedDiscountBookItem>
  );
};

const SCFeedDiscountBookItem = styled.li`
  display: flex;
`;

const SCBookCover = styled.div`
  width: 100%;
  max-width: 110px;
  flex-shrink: 0;
  position: relative;

  ${lessThan('md')} {
    max-width: 85px;
  }
`;

const SCBookInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }

  ${lessThan('md')} {
    margin-left: 8px;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const SCBookName = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCBookGenreWrapper = styled.ul`
`;

const SCGenreLink = styled.a`
  ${TextSmallStyle};
  font-weight: 400;
  color: var(--black-color);
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCBookGenre = styled.li`
  padding-right: 3px;
  flex-shrink: 0;
  display: inline;

  &:not(:last-child) {
    ${SCGenreLink} {
      &:after {
        content: ', ';
      }
    }
  }
`;

const SCBookPrice = styled.div`
  display: flex;
  align-items: center;
  
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const SCOldPrice = styled.span`
  ${TextBoldStyle};
  color: var(--gray-color);
  text-decoration: line-through;
`;

const SCNewPrice = styled.span`
  ${TextBoldStyle};
  color: var(--green3-color);
`;
