import React from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { ActiveLibraryEnum, headerActions } from '~/atomic/organism/Header/header.slice';
import { PopularThemesSlider } from '~/atomic/page/index/FeedPopularThemesBanner/PopularThemesSlider';
import { PageTitleStyle, TextTagStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { useAppDispatch, useAppSelector } from '~/store';

export const FeedPopularThemesBanner = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  const openPopularTagsHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMobileSize) {
      dispatch(headerActions.toggleIsHamburgerActive());
      dispatch(headerActions.changeActiveLibrary(ActiveLibraryEnum.Tag));
    } else {
      dispatch(headerActions.changeActiveLibrary(ActiveLibraryEnum.Tag));
      dispatch(headerActions.setIsOpenLibrary(true));
    }
  };

  return (
    <SCFeedPopularThemesBanner>
      <SCTitle onClick={openPopularTagsHandler}>
        Популярные темы
        <SCTitleArrowIcon />
      </SCTitle>
      <SCSubTitle>
        выбирай и читай то, что нравится
      </SCSubTitle>
      <PopularThemesSlider />
    </SCFeedPopularThemesBanner>
  );
};

const SCFeedPopularThemesBanner = styled.div`
`;

const SCTitleArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 8px;
  color: var(--black-color);
  transition: color 0.3s ease;
`;

const SCTitle = styled.h2`
  ${PageTitleStyle};
  display: flex;
  align-items: baseline;
  cursor: pointer;
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--primary-hover-color);
    
    ${SCTitleArrowIcon} {
      color: var(--primary-hover-color);
    }
  }
`;

const SCSubTitle = styled.span`
  ${TextTagStyle};
  display: flex;
  line-height: 20px;
`;
