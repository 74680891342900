import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { checkMainFilters } from '~/atomic/page/index/checkMainFilters';
import { FavoriteGenresCheckbox } from '~/atomic/page/index/FavoriteGenresCheckbox/FavoriteGenresCheckbox';
import { feedSelector, isShowFeedFavoriteGenresSelector } from '~/atomic/page/index/feed.selector';
import { FeedBanners } from '~/atomic/page/index/FeedBanners';
import { FeedDiscountBooks } from '~/atomic/page/index/FeedDiscountBooks/FeedDiscountBooks';
import { FeedMobileLink } from '~/atomic/page/index/FeedMobileLink';
import { FeedSidebarBanner } from '~/atomic/page/index/FeedSidebarBanner';
import { FeedTabs } from '~/atomic/page/index/FeedTabs';
import { NewFeed } from '~/atomic/page/index/NewFeed/NewFeed';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { VKWidjet } from '~/feature/vk/VKWidjet';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const Feed = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isShowFeedFavoriteGenres = useAppSelector(isShowFeedFavoriteGenresSelector);
  const { feedTab } = useAppSelector(feedSelector);
  const isShowDiscountBooks = feedTab === 'popularity';

  useEffect(() => {
    dispatch(checkMainFilters());
  }, []);

  return (
    <SCFeed>
      <SCHideTitle>
        Bookriver — читайте книги с удовольствием.
      </SCHideTitle>
      <FeedBanners />
      <FeedTabs />
      <SCFeedLayout>
        <NewFeed />
        {!isMobileSize && (
          <SCFeedSidebar>
            {isShowFeedFavoriteGenres && (
              <SCFeedSidebarFavoriteGenresCheckboxWrapper>
                <FavoriteGenresCheckbox
                  listCss={SCFeedSidebarFavoriteGenresCheckboxListClassName}
                />
              </SCFeedSidebarFavoriteGenresCheckboxWrapper>
            )}
            {isShowDiscountBooks ? <FeedSidebarBanner /> : null}
            <FeedDiscountBooks />
            <VKWidjet
              widjetSetting={{ width: 'auto' }}
            />
            <FeedMobileLink />
          </SCFeedSidebar>
        )}
      </SCFeedLayout>
    </SCFeed>
  );
};

const SCFeed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  ${lessThan('lg')} {
    padding-right: 16px;
    padding-left: 16px;
  }

  ${lessThan('sm')} {
    margin-top: 14px;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const SCHideTitle = styled.h1`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const SCFeedLayout = styled.div`
  display: flex;
`;

const SCFeedSidebar = styled.div`
  max-width: 370px;
  width: 100%;
  flex-shrink: 0;
  margin-left: auto;
  
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
  
  ${lessThan('lg')} {
    max-width: 340px;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  
  ${lessThan('md')} {
    max-width: 280px;
  }
  
  ${lessThan('sm')} {
    display: none;
  }
`;

const SCFeedSidebarFavoriteGenresCheckboxWrapper = styled.div`
  ${lessThan('md')} {
    display: none;
  } 
`;

const SCFeedSidebarFavoriteGenresCheckboxListClassName = css` && {
  > * {
    max-width: 50%;
    width: 100%;

    &:nth-child(4n) {
      max-width: 50%;
    }

    &:nth-child(2n) {
      justify-content: flex-end;
    }
  }
}
`;
