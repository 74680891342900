import React from 'react';
import styled from 'styled-components';

import { feedExclusiveBooksSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { FeedBooksSlider } from '~/atomic/page/index/FeedBooksSlider';
import { PageTitleStyle, TextTagStyle } from '~/atomic/Typography';
import { useAppSelector } from '~/store';

export const FeedHotAndExclusiveBooks = () => {
  const books = useAppSelector(feedExclusiveBooksSelector);
  const { isGedFeedLoading } = useAppSelector(feedSelector);

  return (
    <SCFeedHotAndExclusiveBooks>
      <SCTitle>
        Горячие новинки
        <SCFireIcon src="/icon/components/BookSimilarBooks/fire.svg" alt="Иконка огня" />
      </SCTitle>
      <SCSubTitle>
        Наблюдай за созданием незабываемых историй
      </SCSubTitle>
      <FeedBooksSlider
        books={books}
        isLoading={isGedFeedLoading}
      />
    </SCFeedHotAndExclusiveBooks>
  );
};

const SCFeedHotAndExclusiveBooks = styled.div`
  position: relative;
  
  .swiper-slide {
    height: auto;
  }
`;

const SCTitle = styled.h2`
  ${PageTitleStyle};
  line-height: 14px;
  display: flex;
  align-items: center;
`;

const SCSubTitle = styled.span`
  ${TextTagStyle};
  display: flex;
  line-height: 20px;
`;

const SCFireIcon = styled.img`
  margin-left: 7px;
`;
