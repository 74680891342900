import React, {
  useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { FavoriteGenresCheckbox } from '~/atomic/page/index/FavoriteGenresCheckbox/FavoriteGenresCheckbox';
import { getNewFeed } from '~/atomic/page/index/feed.data';
import { feedSelector, isShowFeedFavoriteGenresSelector } from '~/atomic/page/index/feed.selector';
import { FeedAbonnementFilter } from '~/atomic/page/index/FeedAbonnementFilter';
import { NewFeedBooks } from '~/atomic/page/index/NewFeed/NewFeedBooks';
import { NewFeedPopularity } from '~/atomic/page/index/NewFeed/NewFeedPopularity';
import { NewFeedRecommendation } from '~/atomic/page/index/NewFeed/Recommendation/NewFeedRecommendation';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const NewFeed = () => {
  const dispatch = useAppDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const {
    feedTab, genres, recommendationSortingType,
  } = useAppSelector(feedSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isShowFeedFavoriteGenres = useAppSelector(isShowFeedFavoriteGenresSelector);
  const bookWrapperRef = useRef<HTMLDivElement>(null);
  const isPopularityTab = feedTab === FeedTabEnum.Popularity;
  const isDefaultTab = feedTab !== FeedTabEnum.Popularity && feedTab !== FeedTabEnum.Recommended;
  const isUnauthorizationUserOpenRecommendation = feedTab === FeedTabEnum.Recommended && !isLoggedIn;

  useEffect(() => {
    if (!isPopularityTab && !isFirstLoad && !isUnauthorizationUserOpenRecommendation) {
      dispatch(getNewFeed());
    }
    setIsFirstLoad(false);
  }, [feedTab, genres, recommendationSortingType, isLoggedIn]);

  return (
    <SCNewFeed>
      <FeedAbonnementFilter />
      {isMobileSize && isShowFeedFavoriteGenres && (
        <SCFeedMainFavoriteGenresCheckboxWrapper>
          <SCFavoriteGenresCheckbox
            listCss={SCFeedMainFavoriteGenresCheckboxListClassName}
          />
        </SCFeedMainFavoriteGenresCheckboxWrapper>
      )}
      <SCFeedBooksWrapper ref={bookWrapperRef}>
        <NewFeedPopularity />
        <NewFeedRecommendation />
        {feedTab === FeedTabEnum.Discount
          ? (
            <SCDescriptionWrapper>
              <SCDescription>
                Читай больше — плати меньше!
                <br />
                Купи книгу и активируй доступ к секретному каталогу со скидками на 24 часа.
              </SCDescription>
            </SCDescriptionWrapper>
          )
          : null}
        {isDefaultTab ? (
          <NewFeedBooks />
        ) : null}
      </SCFeedBooksWrapper>
    </SCNewFeed>
  );
};

const SCNewFeed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 370px - 30px);

  ${lessThan('lg')} {
    width: calc(100% - 340px - 10px);
  }

  ${lessThan('md')} {
    width: calc(100% - 280px - 10px);
  }

  ${lessThan('sm')} {
    width: 100%;
  }
`;

const SCDescriptionWrapper = styled.div`
  background: var(--white-color);
  padding: 6px 24px;
  margin-bottom: 12px;

  ${lessThan('xs')} {
    padding: 6px 12px;
  }
`;

const SCDescription = styled.span`
  ${TextRegStyle};
  color: var(--black-color);

  ${lessThan('xs')} {
    ${TextSmallStyle};
  }
`;

const SCFeedMainFavoriteGenresCheckboxWrapper = styled.div`
  display: none;
  
  ${lessThan('md')} {
    display: block;
    && {
      margin-bottom: 0;
    }
  }
`;

const SCFeedMainFavoriteGenresCheckboxListClassName = css`
  > * {
    max-width: 50%;

    &:nth-child(2n) {
      justify-content: flex-end;
    }

    &:nth-child(4n) {
      max-width: 50%;
    }
  }
`;

const SCFavoriteGenresCheckbox = styled(FavoriteGenresCheckbox)`
  margin-bottom: 4px;
`;

const SCFeedBooksWrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 30px;

    ${lessThan('sm')} {
      margin-bottom: 20px;
    }
  }
`;
