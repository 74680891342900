import { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import { BookSortingEnum } from '~/api/book/bookApiTypes';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { CommonLayout } from '~/atomic/layout/CommonLayout';
import { LayoutType } from '~/atomic/layout/DefaultLayout';
import { PageTemplate } from '~/atomic/organism/PageTemplate/PageTemplate';
import { Feed } from '~/atomic/page/index/Feed';
import {
  checkMainPageConfirmation, getFeedFromCache,
  getFeedRandomBanners, getGenresFromCookieAndSave, getNewFeed,
  saveFeedTabFromUrl,
} from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { checkAuthToken } from '~/feature/authorization/checkAuthToken';
import { getAuthTokenFromServer } from '~/feature/authorization/getAuthToken';
import { catalogFilterActions } from '~/feature/catalog/filter/catalogFilter.slice';
import { redisClient } from '~/feature/server/redisClient';
import {
  createStore, RootState, useAppDispatch,
} from '~/store';

const MainPage: NextPage & LayoutType = () => {
  const dispatch = useAppDispatch();

  const title = 'Книжный интернет магазин | Читайте книги онлайн';

  const description = 'Книжный магазин Bookriver'
    + ' ⭐️ Литературно-издательская площадка для авторов'
    + ' ⭐️ Серии книг ⭐️ Комментарии и отзывы читателей'
    + ' ⭐️ Скачать произведения полностью в формате fb2'
    + ' ✉️ Найдите интересную книгу на нашем сайте и читайте онлайн с удовольствием';

  return (
    <PageTemplate
      isPublic={pagesConfigStore.home.isPublic}
      onLoadPage={async () => {
        await dispatch(checkMainPageConfirmation());
      }}
      seo={{
        title,
        description,
        canonical: pagesConfigStore.home.url,
      }}
      content={(
        <Feed />
      )}
    />
  );
};

MainPage.getLayout = (page) => (
  <CommonLayout>{ page }</CommonLayout>
);

export const getServerSideProps: GetServerSideProps<{ state: RootState; }> = async (ctx) => {
  const { store, dispatch } = await createStore();
  await checkAuthToken(ctx, dispatch);

  await dispatch(getFeedRandomBanners());
  await dispatch(saveFeedTabFromUrl(ctx));
  await dispatch(getGenresFromCookieAndSave(ctx));
  dispatch(catalogFilterActions.setBookSorting(BookSortingEnum.Rand));
  const token = getAuthTokenFromServer(ctx);
  const { feedTab } = feedSelector(store.getState());

  if (feedTab !== 'popularity') {
    await dispatch(getNewFeed({ token }));
  } else {
    await dispatch(getFeedFromCache({ redisClient, token }));
  }
  const state = store.getState();

  return { props: { state } };
};

export default MainPage;
