import React, { useEffect } from 'react';
import styled from 'styled-components';

import { BookSortingEnum } from '~/api/book/bookApiTypes';
import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { AbonnementPageLink } from '~/atomic/atom/links/staticLinks';
import { getNewFeed } from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import {
  LinkStyle, SCTextReg, TextRegStyle,
} from '~/atomic/Typography';
import { CatalogFilter } from '~/feature/catalog/filter/CatalogFilter';
import { catalogFilterActions } from '~/feature/catalog/filter/catalogFilter.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const FeedAbonnementFilter = () => {
  const dispatch = useAppDispatch();
  const { feedTab } = useAppSelector(feedSelector);

  useEffect(() => {
    dispatch(catalogFilterActions.setIsAddFilterToParams(false));
  }, []);

  const loadTabBooksHandler = () => {
    dispatch(getNewFeed());
  };

  if (feedTab !== FeedTabEnum.Abonnement) return null;

  return (
    <>
      <SCTextReg>
        Абонемент - отличное решение для тех, кто много читает...
        {' '}
        <AbonnementPageLink>
          <SCLink>
            Подробнее
          </SCLink>
        </AbonnementPageLink>
      </SCTextReg>
      <SCCatalogFilter
        isShowSorting
        sortingOptions={{ [BookSortingEnum.Rand]: 'Случайно' } as Record<BookSortingEnum, string>}
        onChange={loadTabBooksHandler}
      />
    </>
  );
};

const SCCatalogFilter = styled(CatalogFilter)`
  margin-bottom: 0;
`;

const SCLink = styled.a`
  ${TextRegStyle};
  ${LinkStyle};
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;
