import React from 'react';
import styled from 'styled-components';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { MyBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { feedActions } from '~/atomic/page/index/feed.slice';
import { NewFeedBooks } from '~/atomic/page/index/NewFeed/NewFeedBooks';
import { NewFeedRecommendationEmptyPlug } from '~/atomic/page/index/NewFeed/Recommendation/NewFeedRecommendationEmptyPlug';
import { NewFeedRecommendationSort } from '~/atomic/page/index/NewFeed/Recommendation/NewFeedRecommendationSort';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';
import { BookLayout } from '~/feature/catalog/filter/BookLayout';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const NewFeedRecommendation = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const {
    feedBooks, feedTab, isLoadFeedBooks, recommendationSortingType, recommendationLayout,
  } = useAppSelector(feedSelector);
  const { userCounters } = useAppSelector(userSelector);

  if (feedTab !== FeedTabEnum.Recommended) return null;

  if ((feedBooks.length === 0 && !isLoadFeedBooks) || !isLoggedIn) {
    return <NewFeedRecommendationEmptyPlug />;
  }

  const changeLayoutHandler = (layout) => {
    dispatch(feedActions.changeRecommendationLayout(layout));
  };

  return (
    <SCNewFeedRecommendation>
      {!isLoadFeedBooks ? (
        <>
          <SCDescriptionWrapper>
            <SCDescription>
              Книги, рекомендованные вам другими читателями с теми же вкусами, что и у вас.
              Оставляйте больше оценок книгам и получайте более точные рекомендации.
              {' '}
              {userCounters?.mybooks?.readWithoutReactions > 0
                ? (
                  <MyBooksLink status="read">
                    <SCReviewReadBooksText>
                      Прочитанные книги без оценки (
                      {userCounters?.mybooks?.readWithoutReactions}
                      )
                    </SCReviewReadBooksText>
                  </MyBooksLink>
                )
                : null}
            </SCDescription>
          </SCDescriptionWrapper>
          <SCSettinsWrapper>
            <NewFeedRecommendationSort
              bookSorting={recommendationSortingType}
              onChangeBookSort={(sortingType) => {
                dispatch(feedActions.changeRecommendationSortingType(sortingType));
              }}
            />
            <BookLayout
              layout={recommendationLayout}
              onChangeLayout={changeLayoutHandler}
            />
          </SCSettinsWrapper>
        </>
      ) : null}
      <NewFeedBooks />
    </SCNewFeedRecommendation>
  );
};

const SCNewFeedRecommendation = styled.div`

`;

const SCDescriptionWrapper = styled.div`
  background: var(--white-color);
  padding: 6px 24px;
  margin-bottom: 12px;

  ${lessThan('xs')} {
    padding: 6px 12px;
  }
`;

const SCDescription = styled.span`
  ${TextRegStyle};
  color: var(--black-color);

  ${lessThan('xs')} {
    ${TextSmallStyle};
  }
`;

const SCSettinsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SCReviewReadBooksText = styled.a`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: var(--primary-color);
`;
