import { useEffect, useState } from 'react';

import { feedActiveGenreSlugsSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { feedPopularThemesBannerItems } from '~/atomic/page/index/FeedPopularThemesBanner/feedPopularThemesBannerItems';
import { useAppSelector } from '~/store';

const deleteItemFromArray = (index: number, array: Array<any>) => {
  return array.filter((_, i) => i !== index);
};

const includeRandomItems = (count: number, array: Array<any>) => {
  const result = [];
  let copy = array.concat();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * copy.length);

    if (copy[randomIndex]) {
      result.push(copy[randomIndex]);
      copy = deleteItemFromArray(randomIndex, copy);
    } else {
      break;
    }
  }

  return result;
};

export const useGetRandomFeedPopularTheme = () => {
  const [randomBanners, changeRandomBanners] = useState([]);
  const feedActiveGenreSlugs = useAppSelector(feedActiveGenreSlugsSelector);
  const { isGedFeedLoading } = useAppSelector(feedSelector);

  useEffect(() => {
    if (!isGedFeedLoading) {
      const filteredPopularThemes = feedPopularThemesBannerItems
        .filter((banner) => {
          const isInclude = banner.includes.some((genre) => feedActiveGenreSlugs.includes(genre)) || banner.includes[0] === 'all';
          const isExclude = 'excludes' in banner ? banner.excludes.some((genre) => feedActiveGenreSlugs.includes(genre)) : false;

          return isInclude && !isExclude;
        });
      changeRandomBanners(includeRandomItems(10, filteredPopularThemes));
    }
  }, [isGedFeedLoading, feedActiveGenreSlugs]);

  return randomBanners;
};
