import React from 'react';
import styled from 'styled-components';

import { BookSortingEnum } from '~/api/book/bookApiTypes';
import { Button } from '~/atomic/atom/Button';
import { GenreLink, MyBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import {
  TextBoldStyle, TextRegStyle, TextTagStyle,
} from '~/atomic/Typography';
import { openLoginModal } from '~/feature/authorization/auth.data';
import { reactionsList } from '~/feature/myBooks/reactions/reactionsList';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { BookReactionRatingEnum } from '~/graphql/books/factory/book/BookFactoryTypes';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const NewFeedRecommendationEmptyPlug = () => {
  const dispatch = useAppDispatch();
  const { userCounters } = useAppSelector(userSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  const onLoginClick = async () => {
    dispatch(openLoginModal());
  };

  return (
    <SCNewFeedRecommendationEmptyPlug>
      <SCTitle>
        Рекомендации от других читателей
      </SCTitle>
      <SCDescription>
        Чтобы начать получать рекомендации, поставьте как можно больше оценок уже прочитанным вами книгам:
      </SCDescription>
      <SCReactionWrapper>
        {reactionsList
          .filter((reaction) => reaction.name !== BookReactionRatingEnum.NotRated)
          .map((reaction) => (
            <SCReaction key={reaction.name}>
              <SCReactionImage src={reaction.icon} alt={`иконка реакции: ${reaction.label}`} />
              <SCReactionText>
                {reaction.label}
              </SCReactionText>
            </SCReaction>
          ))}
      </SCReactionWrapper>
      {isLoggedIn && userCounters?.mybooks?.read > 0
        ? (
          <MyBooksLink status="read">
            <SCReviewBooksText>
              <Button
                type="primary"
                size="large"
              >
                Оценить прочитанные книги
              </Button>

            </SCReviewBooksText>
          </MyBooksLink>
        )
        : null}
      {isLoggedIn && userCounters?.mybooks?.read === 0
        ? (
          <GenreLink sortingType={BookSortingEnum.Bestseller}>
            <SCReviewBooksText>
              <Button
                type="primary"
                size="large"
              >
                Найти прочитанные книги
              </Button>
            </SCReviewBooksText>
          </GenreLink>
        )
        : null}
      {!isLoggedIn
        ? (
          <SCButton
            type="primary"
            size="large"
            onClick={onLoginClick}
          >
            Войти в аккаунт
          </SCButton>
        )
        : null}
    </SCNewFeedRecommendationEmptyPlug>
  );
};

const SCNewFeedRecommendationEmptyPlug = styled.div`
  ${PaperStyle};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
`;

const SCTitle = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
  margin-bottom: 18px;
`;

const SCDescription = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
  max-width: 440px;
  margin-bottom: 30px;
`;

const SCReactionWrapper = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;

  ${lessThan('lg')} {
    grid-template-columns: 1fr;
  }
`;

const SCReaction = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 210px;
  
  &:nth-child(1) {
    order: 1;
  }

  &:nth-child(2) {
    order: 4;
  }

  &:nth-child(3) {
    order: 2;
  }

  &:nth-child(4) {
    order: 5;
  }

  &:nth-child(5) {
    order: 3;
  }
  
  ${lessThan('md')} {
    order: initial !important;
  }
`;

const SCReactionImage = styled.img`
  width: 48px;
  margin-right: 16px;
`;

const SCReactionText = styled.span`
  ${TextTagStyle};
  line-height: 16px;
  color: var(--gray-color);
`;

const SCButton = styled(Button)`
  margin-top: 50px;
`;

const SCReviewBooksText = styled.a`
  display: inline-flex;
  align-items: center;
  ${TextRegStyle};
  color: var(--primary-color);
  margin-top: 50px;
`;
