import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { BookriverMobileLink } from '~/atomic/atom/BookriverMobileLink';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { TextTagStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { useIsClientRender } from '~/lib/hooks/useIsClientRender';
import { isAndroidDevice } from '~/lib/utils/isAndroidDevice';
import { useAppSelector } from '~/store';

export const FeedMobileLink = () => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isAndroid = isAndroidDevice();
  const isClient = useIsClientRender();

  if ((!isAndroid && isMobileSize) || !isClient) {
    return null;
  }

  return (
    <SCFeedMobileLink>
      <SCText>
        Покупайте и читайте книги в приложении Bookriver
      </SCText>
      <BookriverMobileLink>
        <Image
          width="127"
          height="38"
          src="/image/google_dowload_app.jpg"
          alt="изображение google store"
        />
      </BookriverMobileLink>
    </SCFeedMobileLink>
  );
};

const SCFeedMobileLink = styled.div`
  ${PaperStyle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
`;

const SCText = styled.span`
  ${TextTagStyle};
  color: var(--black-color);
  display: inline-flex;
  padding-bottom: 12px;
  text-align: center;
`;
