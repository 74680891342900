import { useQuery } from 'react-query';

import { Book } from '~/api/book/bookApiTypes';
import { discountBooksApi } from '~/api/book/discountBooksApi';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { useAppSelector } from '~/store';

export const useGetFeedDiscountBooks = () => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const initialData = [] as Book[];

  const result = useQuery(
    ['feedDiscountBooks'],
    async () => {
      const response = await discountBooksApi.getWidgetDiscountBooks({ limit: isMobileSize ? 9 : 3 });
      return response.data;
    },
    { placeholderData: initialData },
  );

  return { ...result, isLoading: result.isPlaceholderData };
};
