import React from 'react';
import styled from 'styled-components';

import { feedRecommendedBooksSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { FeedBooksSlider } from '~/atomic/page/index/FeedBooksSlider';
import { PageTitleStyle, TextTagStyle } from '~/atomic/Typography';
import { buyBookFromBookriverRecommendsQueryName } from '~/feature/yandex/trackingBuyBookriverRecommends';
import { useAppSelector } from '~/store';

export const FeedBookriverRecommends = () => {
  const books = useAppSelector(feedRecommendedBooksSelector);
  const { isGedFeedLoading } = useAppSelector(feedSelector);

  return (
    <SCFeedBookriverRecommends>
      <SCTitle>
        Букривер рекомендует
      </SCTitle>
      <SCSubTitle>
        Открой для себя новых авторов
      </SCSubTitle>
      <FeedBooksSlider
        books={books}
        isLoading={isGedFeedLoading}
        bookCardProps={{ bookLinkQueries: { [buyBookFromBookriverRecommendsQueryName]: true } }}
      />
    </SCFeedBookriverRecommends>
  );
};

const SCFeedBookriverRecommends = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;
  }
`;

const SCTitle = styled.h2`
  ${PageTitleStyle};
  display: flex;
  align-items: center;
`;

const SCSubTitle = styled.span`
  ${TextTagStyle};
  display: flex;
  line-height: 20px;
`;
