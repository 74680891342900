import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import {
  changeFeedGenres, fantasyFeedGenres, loveFeedGenres,
} from '~/atomic/page/index/feed.data';
import { feedSliceName } from '~/atomic/page/index/feed.slice';

export const checkMainFilters = createAsyncThunk(
  `${feedSliceName}/checkMainFilters`,
  (_, thunkAPI) => {
    const parsedLocation = qs.parseUrl(String(window.location));
    const { main } = parsedLocation.query;

    if (main) {
      if (main === 'love') {
        thunkAPI.dispatch(changeFeedGenres({ genres: loveFeedGenres }));
      }

      if (main === 'ff') {
        thunkAPI.dispatch(changeFeedGenres({ genres: fantasyFeedGenres }));
      }
    }
  },
);
