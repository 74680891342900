import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { RootState } from '~/store';

export const buyBookFromFeedBannerQueryName = 'buyBookFromFeedBanner';

export const trackingGoToBookFromFeedBanner = createAsyncThunk(
  'trackingGoToBookFromFeedBanner',
  () => {
    ym('reachGoal', 'go-to-feed-banner');
  },
);

export const saveMoveOnTrackingFeedBannerBook = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'saveMoveOnTrackingFeedBannerBook',
  async ({ book }, thunkAPI) => {
    const parsedLocation = qs.parseUrl(String(window.location));

    if (parsedLocation.query[buyBookFromFeedBannerQueryName]) {
      parsedLocation.query[buyBookFromFeedBannerQueryName] = undefined;

      if (window.localStorage) {
        const oldStore = JSON.parse(localStorage.getItem(buyBookFromFeedBannerQueryName)) ?? {};

        const newStore = {
          ...oldStore,
          [book.slug]: true,
        };

        localStorage.setItem(buyBookFromFeedBannerQueryName, JSON.stringify(newStore));
      }

      const url = new URL(qs.stringifyUrl(parsedLocation));

      const redirectUrl = url.pathname + url.search + url.hash;

      await Router.replace(Router.pathname, redirectUrl, { shallow: true });
    }
  },
);

export const trackingBuyBookFromFeedBanner = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'trackingBuyBookFromFeedBanner',
  async ({ book }, thunkAPI) => {
    if (window.localStorage) {
      const store = JSON.parse(localStorage.getItem(buyBookFromFeedBannerQueryName)) ?? {};

      const isTrackingBook = book.slug in store;

      if (isTrackingBook) {
        ym('reachGoal', 'buy-book-from-feed-banner');
        delete store[book.slug];
        localStorage.setItem(buyBookFromFeedBannerQueryName, JSON.stringify(store));
      }
    }
  },
);
