import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { FreeMode } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { TagLink } from '~/atomic/atom/links/dynamicLinks';
import { SkeletonInput } from '~/atomic/atom/Skeleton';
import { Slider } from '~/atomic/atom/Slider';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { useGetRandomFeedPopularTheme } from '~/atomic/page/index/FeedPopularThemesBanner/useGetRandomFeedPopularTheme';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { useAppSelector } from '~/store';

export const PopularThemesSlider = () => {
  const randomBanners = useGetRandomFeedPopularTheme();
  const feed = useAppSelector(feedSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  return (
    <SCWrapper>
      <Slider
        modules={[FreeMode]}
        freeMode
        slidesPerView="auto"
        spaceBetween={12}
        breakpoints={{ 562: { spaceBetween: 24 } }}
      >
        {feed.isGedFeedLoading && Array(3).fill('dummy').map(() => (
          <SwiperSlide>
            <SCSkeleton active />
          </SwiperSlide>
        ))}
        {feed.isGedFeedLoading
          ? null
          : randomBanners.map((banner) => (
            <SwiperSlide key={banner.themeSlug}>
              <TagLink prefetch={false} slug={banner.themeSlug}>
                <a>
                  <SCBanner
                    width={isMobileSize ? 180 : 225}
                    height={isMobileSize ? 120 : 150}
                    src={`/image/main/FeedPopularThemesBanner/${banner.themeSlug}.jpg`}
                    alt={`баннер об теме: "${banner.alt}"`}
                    quality={90}
                  />
                </a>
              </TagLink>
            </SwiperSlide>
          ))}
      </Slider>
    </SCWrapper>
  );
};

const SCWrapper = styled.div`
  position: relative;

  .swiper-slide {
    width: auto;
  }
`;

const SCSkeleton = styled(SkeletonInput)`
  overflow: hidden;
  width: 225px !important;
  height: 150px !important;
`;

const SCBanner = styled(Image)`
  background-color: hsla(0,0%,74.5%,.2);
`;
