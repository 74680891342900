import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { LoaderIcon } from '~/atomic/atom/icon/LoaderIcon';
import { loadMoreBestsellers } from '~/atomic/page/index/feed.data';
import { feedBestsellersBooksSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { feedActions } from '~/atomic/page/index/feed.slice';
import {
  PageTitleStyle, TextRegStyle, TextTagStyle,
} from '~/atomic/Typography';
import { BookCard } from '~/feature/book/BookCard/BookCard';
import { isMobileSizeSelector, isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import { DEFAULT_PAGE_SIZE } from '~/feature/pagination/pagination.slice';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const FeedBestsellersBooks = () => {
  const dispatch = useAppDispatch();
  const books = useAppSelector(feedBestsellersBooksSelector);
  const isTabletSize = useAppSelector(isTabletSizeSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const { loadMorePage, isLoadMoreBestsellersLoading } = useAppSelector(feedSelector);
  const isHideShowMore = loadMorePage > 4;
  const isShowMoreButton = books?.length > ((isTabletSize || isMobileSize) ? 5 : 7);

  const showMoreHandler = async () => {
    if (!isLoadMoreBestsellersLoading) {
      await dispatch(loadMoreBestsellers());
      dispatch(feedActions.changeLoadMorePage(loadMorePage + 1));
    }
  };

  if (books?.length === 0) {
    return <></>;
  }

  return (
    <SCFeedBestsellersBooks>
      <Link
        as={`/genre?page=1&perPage=${DEFAULT_PAGE_SIZE}&sortingType=bestseller`}
        href={`/genre?page=1&perPage=${DEFAULT_PAGE_SIZE}&sortingType=bestseller`}
        passHref
        prefetch={false}
      >
        <SCTitle>
          Бестселлеры
          <SCTitleArrowIcon />
        </SCTitle>
      </Link>
      <SCSubTitle>
        Самые любимые читателями книги
      </SCSubTitle>
      <SCGrid>
        {books
          ?.map((book) => (
            <BookCard
              key={book.slug}
              book={book}
            />
          ))}
      </SCGrid>
      {isShowMoreButton && !isHideShowMore && (
        <SCShowMore
          onClick={showMoreHandler}
        >
          Показать больше
          {isLoadMoreBestsellersLoading
            ? <SCLoaderIcon />
            : <SCArrowIcon />}
        </SCShowMore>
      )}
    </SCFeedBestsellersBooks>
  );
};

const SCFeedBestsellersBooks = styled.div`
  padding-bottom: 30px;

  ${lessThan('sm')} {
    padding-bottom: 15px;
  }
`;

const SCTitleArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 8px;
  color: var(--black-color);
  transition: color 0.3s ease;
`;

const SCTitle = styled.a`
  display: flex;
  align-items: baseline;
  ${PageTitleStyle};
  
  &:hover {
    color: var(--primary-hover-color);
    
    ${SCTitleArrowIcon} {
      color: var(--primary-hover-color);
    }
  }
`;

const SCShowMore = styled.div`
  ${TextRegStyle};
  color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 500;
  line-height: 14px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 12px;
  transform: rotate(90deg);
  color: var(--gray-color);
`;

const SCLoaderIcon = styled(LoaderIcon)`
  width: 14px;
  height: 14px;
  margin-left: 8px;
`;

const SCSubTitle = styled.span`
  ${TextTagStyle};
  display: flex;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const SCGrid = styled.div`
  --column-gap: 20px;
  --row-gap: 20px;
  --items-count: 4;
  --column-gap-count: calc(var(--items-count) - 1);
  --column-gaps-width: calc(var(--column-gap) * var(--column-gap-count));
  --free-space-for-items: calc(100% - calc(var(--column-gaps-width)));
  --item-width: calc(var(--free-space-for-items) / var(--items-count));

  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(var(--items-count), var(--item-width));
  grid-gap: var(--row-gap) var(--column-gap);
  width: 100%;

  ${lessThan('md')} {
    --items-count: 3;
    --column-gap: 14px;
    --row-gap: 18px;
  }

  ${lessThan('sm')} {
    --items-count: 4;
    --column-gap: 6px;
    --row-gap: 8px;
  }

  ${lessThan('xs')} {
    --items-count: 3;
  }
`;
