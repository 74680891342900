import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { BookLayoutEnum } from '~/api/book/bookApiTypes';
import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { SCCatalogList } from '~/atomic/page/catalog/styled';
import { loadMoreFeedBooks } from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { SCGrid } from '~/atomic/page/index/FeedBestsellersBooks';
import { CSSSkeleton } from '~/atomic/page/index/styled';
import { BookCard } from '~/feature/book/BookCard/BookCard';
import { BookListCard } from '~/feature/book/BookCard/BookListCard';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const NewFeedBooks = () => {
  const dispatch = useAppDispatch();
  const {
    feedTab,
    feedBooks,
    isLoadFeedBooks,
    isLoadMoreFeedBooksLoading,
    isDisableShowMore,
    recommendationLayout,
  } = useAppSelector(feedSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const { page } = useAppSelector(paginationSelector);
  const { ref, inView } = useInView({ threshold: 0 });

  const showMoreHandler = () => {
    dispatch(paginationActions.setPage({ page: page + 1 }));
    dispatch(loadMoreFeedBooks());
  };

  useEffect(() => {
    if (inView && !isDisableShowMore) {
      showMoreHandler();
    }
  }, [inView]);

  if (recommendationLayout === BookLayoutEnum.LIST && feedTab === FeedTabEnum.Recommended) {
    return (
      <SCCatalogList
        style={{ gridTemplateColumns: '1fr' }}
      >
        {!isLoadFeedBooks ? feedBooks
          ?.map((book, index) => (
            <BookListCard
              ref={(bookRef) => {
                if (feedBooks.length === index + 1) {
                  ref(bookRef);
                }
              }}
              key={book.slug}
              book={book}
            />
          )) : null}
        {isLoadFeedBooks || isLoadMoreFeedBooksLoading ? Array(16)
          .fill('dummy')
          .map((dummy, index) => (
            <CSSSkeleton key={index} active />
          )) : null}
      </SCCatalogList>
    );
  }

  return (
    <SCGrid>
      {!isLoadFeedBooks ? feedBooks
        ?.map((book, index) => (
          <BookCard
            ref={(bookRef) => {
              if (feedBooks.length === index + 1) {
                ref(bookRef);
              }
            }}
            key={book.slug}
            book={book}
          />
        )) : null}
      {isLoadFeedBooks || isLoadMoreFeedBooksLoading ? Array(16)
        .fill('dummy')
        .map((dummy, index) => (
          <CSSSkeleton key={index} active />
        )) : null}
    </SCGrid>
  );
};
